export const API_VERSION = "v1.0";
export const SOCKET_URL = "ws://localhost:5000/messages";
export const COM_PORT = "COM_PORT";
export const IP_ADDRESS = "IP_ADDRESS";
export const PORT = "PORT";

export const ARENA_TOKEN = ``;
export const SUPER_ADMIN_TOKEN = ``;
export const IMACULIX_TOKEN = ``;
export const STUSSIHOF_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjAzNmNhYTcyLWU5YWQtNGM0ZC05MzFkLTVlMjY1Mjg0NzI3YSIsImlhdCI6MTYyNDg3OTU2MCwiZXhwIjoxNjI3NDcxNTYwfQ.1oZqMj1Q66krvo0qNWAhQE8ML5azR6hQH_02zQmQSji4`;
export const TOKEN = STUSSIHOF_TOKEN;
const HOST_URL = `stuessihof-stg-kiosk.brij.tech`;

const LIVEBASEURL = `https://api-stuessihof-stg.brij.tech`;
const DEVBASEURL = ``;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;

export const BASEURL =
  window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;

//  mode should be an integer
// 1- only ticket
// 2- only fnb
// 3 - both

export const mode = 3;
